.activity-card-container-bull,
.activity-card-container {
  height: 90px;
  border-radius: 10px;
  margin: 8px;
  position: relative;
  .check-activity {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    margin-bottom: 3px;

    .button-check-active {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-left: 7px;
      margin-right: 5px;
      text-align: center;
      cursor: pointer;
      background-color: #46d19e;
      border-radius: 7px;
      border-style: solid;
      border-width: 1px;
      border-color: white;
    }
    .button-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-left: 7px;
      margin-right: 5px;
      text-align: center;
      background-color: gray;
      border-radius: 7px;
      border-width: 2px;
      border-color: white;
      opacity: 0.3;

      cursor: pointer;
    }
    .title-activity {
      margin: 0;
      color: white;
      font-weight: 700;
      font-size: 20px;
      margin-left: 10px;
    }
  }
  .description-box {
    display: flex;
    flex-direction: row;
    padding-left: 42px;
    align-items: center;
    .description-activity {
      margin: 0;
      color: white;
      font-weight: 400;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .more-info-container {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;

    .more-info-on-click {
      position: relative;

      .more-info {
        width: 70px;
        height: 25px;
        background-color: black;
        color: white;
        opacity: 0.2;
        margin-left: auto;
        border-radius: 10px 0px 10px 0px;
      }
      .more-info-text {
        width: 70px;
        font-size: 12px;
        right: 0;
        text-align: center;
        margin-top: 3px;
        position: absolute;
        top: 0;
        color: white;
        font-weight: 700;
      }
    }
  }
}

@media only screen and (max-width: 615px) {
  .activity-card-container-bull,
  .activity-card-container {
    width: calc(100% - 20px);
    margin: 8px auto;
  }
}
