.activities-counter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 0;

  .activities-counter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 700;
  }

  .selected-activities {
    margin: 0;
    font-size: 12px;
    margin-left: 7px;
    font-weight: 700;
  }

  .evening-color {
    color: white;
  }

  .day-color {
    color: #56576f;
  }
}
