.times-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 125px;

  .times-box {
    height: 68px;
    background-color: silver;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .times-box-evening {
    height: 48px;
    background-color: blue;
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
