.modal-add-new-week {
  height: 250px !important;
  height: 100%;
  border-radius: 20px;
  padding: 20px;

  .modal-add-new-week-container {
    background-color: #f8f5ff;
    border-radius: 20px;
    text-align: center;

    h1 {
      font-size: 22px;
      color: #56576f;
      font-weight: bold;
    }

    .modal-program-buttons {
      display: flex;
      justify-content: space-around;

      .button-add-week {
        border-radius: 15px;
        color: white;
        font-weight: bold;
        padding: 5px 40px;
        background-color: #46d19e;
        cursor: pointer;
      }

      .buttom-continue {
        cursor: pointer;
        padding: 10px;
        width: 200px;
        height: 37px;
        text-align: center;
        justify-content: center;
        border-radius: 20px;
        font-weight: 700;
        cursor: pointer;
        color: white;
        background-color: #56576f;
        align-items: center;
        display: flex;
        margin-right: 10px;
      }
    }
  }
}
