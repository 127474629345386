.pdf {
  font-weight: bold;

  .pdf-table-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
}
