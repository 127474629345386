:root {
  --space: 2rem;
}

.Shimmer-shimmer-1-1-1 {
  background-size: cover !important;
}
.sticky-sidebar {
  --offset: var(--space);
  flex-grow: 1;
  align-self: start;
  position: sticky !important;
  top: var(--offset);
}

.timeline-container-evening,
.timeline-container {
  width: 380px;
  min-height: 400px;
  height: auto;
  background-color: #f8f5ff;
  border-radius: 15px 15px 2px 15px;
  margin: 5px;
  position: relative;
  padding-bottom: 55px;

  .unlock-day-button {
    width: 150px;
    background-color: #3cd19e;
    border-color: #3cd19e;
    border-radius: 20px;
    display: flex;
    align-items: center;

    .unlock-day-button-text {
      margin: auto;
      font-weight: 700;
      font-size: 15px;
    }
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .timeline-title {
    color: #56576f;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
  }
  .timeline-title-evening {
    margin: 0;
    color: white;
    font-weight: 700;
    font-size: 18px;
  }

  .category-title {
    color: white;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    p {
      opacity: 0.5;
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      width: 90px;
    }

    div {
      margin: 0;
      margin-right: 30px;
      height: 2px;
      width: calc(100% - 100px);
      background-color: #ffffff;
      opacity: 0.3;
    }
  }

  .show-time-row {
    margin: 5px 20px;
    display: flex;
    flex-direction: column;

    p {
      margin: 8px 0 8px 0;
      color: white;
      font-size: 12px;
    }

    .show-time-row-block {
      display: flex;
      color: white;
      width: fit-content;
      font-size: 12px;
      font-weight: 700;
      margin: 5px 0 5px 0;
      height: 25px;
      border-radius: 17px;
      padding: 0 7px;
      align-items: center;
      margin-left: 10px;

      .delete-activity {
        margin: 0;
        margin-left: 5px;
        font-size: 18px;
        font-weight: 600;

        z-index: 10;
        color: #ffffff;
        opacity: 0.6;
        cursor: pointer;

        > svg {
          opacity: 0.6 !important;
          cursor: pointer !important;
        }
      }
    }
  }

  .columns-timeline-evening,
  .columns-timeline {
    display: flex;
    flex-direction: row;

    .time-line {
      font-size: 12px;
      margin: 5px 0 5px 20px;
      height: 25px;
      display: flex;
      align-items: center;
    }
    .time-line-evening {
      font-size: 12px;
      margin: 5px 0 5px 20px;
      height: 25px;
      display: flex;
      align-items: center;
      color: rgb(244, 244, 244);
    }
  }
  .columns-timeline-evening {
    min-height: 250px;
  }
  .timeline-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .day {
    font-size: 14px !important;
    height: 30px;
    width: 130px;
    background-color: #e4dff2;
    text-align: center;
    padding-left: 3px;
    border-radius: 20px;
    font-weight: 700;
    cursor: pointer;
    align-items: center;
    display: flex;
    color: #56576f;
    font-weight: 700;

    p {
      margin: 0;
      width: 100%;
      text-align: center;
    }

    > svg {
      width: 25px;
    }
  }

  .day:hover {
    background-color: #ccc5e0;
  }

  .day-active {
    font-size: 14px;
    height: 30px;
    width: 130px;
    background-color: #e4dff2;
    text-align: center;
    padding-left: 3px;
    border-radius: 20px;
    font-weight: 700;
    cursor: pointer;
    color: white;
    background-color: #56576f;
    align-items: center;
    display: flex;

    p {
      margin: 0;
      text-align: center;
      width: 100%;
    }

    > svg {
      width: 25px;
    }
  }

  .activities-counter-container {
    margin-bottom: 20px;
  }
}

.key-value {
  display: flex;

  .activities-map-container {
    margin-left: 10px;
    .activities-day {
      display: flex;
      color: white;
      width: fit-content;
      font-size: 12px;
      font-weight: 700;
      margin: 5px 0 5px 0;
      height: 25px;
      border-radius: 17px;
      padding: 0 7px;
      align-items: center;
      .delete-activity {
        margin: 0;
        margin-left: 5px;
        font-size: 18px;
        font-weight: 600;

        z-index: 10;
        color: #ffffff;
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
}

.timeline-container-evening {
  background-color: #271854;
  border-radius: 15px 2px 15px 15px;
  margin: 5px;
  position: relative;
  min-height: 400px;
}

@media screen and (max-width: 900px) {
  .timeline-container {
    width: 100%;
    border-radius: 15px;
  }
  .timeline-container-evening {
    width: 100%;
    border-radius: 15px;
  }
  .sticky-sidebar {
    position: relative !important;
    top: 0;
  }
}
