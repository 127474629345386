.admins-container {
  height: 400px;
  width: 100%;
  padding: 20px;

  .admin-add-button-container {
    margin-bottom: 20px;
  }

  .admin-remove-button-container {
    margin-top: 20px;
  }

  .add-admin-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    & .MuiTextField-root {
      width: 100% !important;
    }

    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: red;
    }

    & .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
      color: red;
    }
  }

  .Mui-checked {
    color: red !important;
  }
}

@media only screen and (max-width: 890px) {
  .add-admin-section {
    flex-direction: column;
    & .MuiTextField-root {
      margin: 0 0 10px 0 !important;
      width: auto !important;
    }
  }
}
