.combination-timeline {
  margin: 10px;

  .combination-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .combination-column {
      .combination-row-time {
        padding: 10px 0px;
        font-size: 18px;
        svg {
          margin-left: 10px !important;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 662px) {
  .combination-timeline {
    text-align: center;

    .combination-row {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      text-align: center;

      .combination-column {
        text-align: center;
      }
    }
  }
}
