.pdf-header {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 3px;

  .pdf-header-box {
    width: 125px;
    height: 30px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
