.order-state-span {
  height: 2;
  font-weight: 600;
  letter-spacing: 0.5px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: red;
  border-radius: 12px;
  padding: 2px 10px;
  color: #fff;
}

.dashboard-modal {
  .modal-content {
    background-color: #fff !important;
  }
}

.order-table-container {
  padding: 20px;
  height: 410px;
}
