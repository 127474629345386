.price-container {
  align-items: center;

  .price-title {
    font-weight: 700;
    color: #56576f;
    margin: 0;
  }
  .price-number {
    font-weight: 700;
    color: #56576f;
    margin: -5px 0;
    font-size: 30px;
    width: 50;
    margin-top: 10;
    border-radius: 10;
    text-align: "center";
  }
}

@media screen and (max-width: 577px) {
  .price-container {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    .date-container {
      margin-right: -10px;
    }
  }
}
