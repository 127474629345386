.dashboard-index {
  margin-top: 20px;
  a {
    text-decoration: none !important;
  }
  .dashboard-card {
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    text-decoration: none;

    h3,
    p {
      margin: 0;
      font-weight: 600;
    }

    p {
      display: flex;
      justify-content: flex-end;
      font-size: 25px;
    }
  }

  .blue-gradient {
    background: rgb(2, 0, 36);
    background: linear-gradient(
      156deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 41%,
      rgba(0, 212, 255, 1) 100%
    );
  }

  .red-gradient {
    background: rgb(121, 9, 9);
    background: linear-gradient(
      156deg,
      rgba(121, 9, 9, 1) 0%,
      rgba(255, 0, 0, 1) 50%,
      rgba(181, 0, 255, 1) 100%
    );
  }

  .green-gradient {
    background: rgb(9, 121, 13);
    background: linear-gradient(
      156deg,
      rgba(9, 121, 13, 1) 0%,
      rgba(136, 255, 0, 1) 50%,
      rgba(0, 204, 255, 1) 100%
    );
  }

  .orange-gradient {
    background: rgb(121, 27, 9);
    background: linear-gradient(
      156deg,
      rgba(121, 27, 9, 1) 0%,
      rgba(255, 160, 0, 1) 50%,
      rgba(0, 255, 31, 1) 100%
    );
  }
}

@media only screen and (max-width: 1200px) {
  .dashboard-card {
    margin-bottom: 10px;
  }
}
