.auth-component {
  margin: 10px 10px;
  color: #fff;

  .auth-component-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    > p {
      margin: 0;
    }

    > img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }

  .login-image {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .user-name {
    display: none;
  }
}
