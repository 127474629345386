.s4-container {
  margin-top: 7%;
  margin-bottom: 7%;

  .s4-pdf-container {
    max-width: 400px;
    width: 100%;
    margin: auto;
  }

  .s4-program-done {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    margin: auto;
    justify-content: center;

    .s4-congratulation-icon-container {
      margin-right: 15px;
    }

    .s4-congratulation {
      margin-top: 7%;
      font-size: 40px;
      color: white;
      font-weight: 600;
      margin: 0;
      margin-right: 10px;
    }

    .s4-your-program {
      font-size: 40px;
      font-weight: 200;
      margin: 0;
      color: white;
    }
  }

  .s4-button-accept-container {
    text-align: center;

    .s4-button-accept {
      width: 150px;
      margin-top: 50px;
      background-color: #3cd19e;
      border-color: #3cd19e;
      border-radius: 20px;
      color: white;
      border: none;
      outline: none;
      margin: 20px 0 50px 0;

      > p {
        margin: auto;
        font-weight: 700;
        font-size: 15px;
      }
    }

    .s4-button-accept:hover {
      background-color: #4ae2ae;
      border-color: #4ae2ae;
      color: white;
    }
  }

  .s4-info-program {
    margin: auto;
    margin-top: 15px;
    padding: 0 15px;
    text-align: center;
    color: white;
    max-width: 660px;
    font-weight: 300;
  }
}
