.snack-bar {
  border-radius: 15px;
  padding: 5px 10px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  .snack-bar-body {
    display: flex;
    align-items: center;

    .left-icon-container {
      margin-right: 10px;

      svg {
        width: 25px;
      }
    }
    .text {
      color: white;
      margin: 0;
      font-weight: 700;
      margin-right: 10px;
      text-align: center;
    }
    .icon {
      opacity: 0.6;
      cursor: pointer;
    }
  }
}
