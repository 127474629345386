.single-day-title {
  text-align: center;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.progress-bar-section {
  max-width: 500px;
  margin: auto;
  margin-bottom: 20px;
  padding: 10px;

  .progress-bar {
    background-color: #46d19e !important;
  }
  .progress {
    height: 7px !important;
  }

  .percentage {
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 700;
  }
}

.select-activities-section {
  .timeline-activities-row {
    display: flex;
    align-items: flex-start;
    padding: 0 5px;
  }
  .timeline-activities-row-evening {
    display: flex;
    padding: 0 5px;
  }
}

@media only screen and (max-width: 900px) {
  .select-activities-section {
    .timeline-activities-row {
      display: flex;
      flex-wrap: wrap;
      padding: 0 5px;
    }
    .timeline-activities-row-evening {
      display: flex;
      flex-wrap: wrap;
      padding: 0 5px;
    }
  }
}
