.summary-selection-container {
  max-width: 1400px;
  min-height: 150px;
  background-color: #f8f5ff;
  margin: auto;
  border-radius: 20px;
  margin-top: 5%;
  padding: 10px;
  display: flex;
  justify-content: center;

  .sub-container > div {
    margin: 0 10px 0 10px;
  }
  .sub-container {
    width: 100%;
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    margin-top: 25px;

    .summary-row {
      height: 80px;

      .button-back-s3 {
        width: 150px;
        background-color: #e4dff2;
        border-color: #e4dff2;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 15px;
        color: #56576f;
        outline: none;
        border: none;

        > p {
          margin: auto;
        }
      }
      .button-back-s3:hover {
        background-color: #ccc5e0;
      }
      .summary-row-title {
        margin: 0;
        margin-bottom: 5px;
        color: #56576f;
        font-weight: 600;
      }
      .summary-row-price {
        color: #56576f;
        margin: 0;
        height: 30px;
        font-weight: 700;
        font-size: 30px;
        display: flex;
        align-items: center;
      }
      .sum-option {
        width: 45px;
        height: 30px;
        text-align: center;
        justify-content: center;
        border-radius: 20px;
        font-weight: 700;
        color: white;
        background-color: #56576f;
        align-items: center;
        display: flex;
      }
      .day-evening-row {
        display: flex;
        .day-evening-option {
          margin: auto;
          width: 100px;
          height: 30px;
          text-align: center;
          justify-content: center;
          border-radius: 20px;
          font-weight: 700;
          color: white;
          background-color: #56576f;
          align-items: center;
          display: flex;
          margin-right: 10px;
        }
      }
      .date-sum {
        margin: 0;
        height: 30px;
        padding: 0 15px;
        text-align: center;
        justify-content: center;
        border-radius: 20px;
        font-weight: 700;
        color: white;
        background-color: #56576f;
        align-items: center;
        display: flex;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .summary-row {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .sub-container {
    flex-direction: column;
    text-align: center;
    align-items: center !important;

    .sum-option {
      margin: auto;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .summary-selection-container {
    margin: 10px;
    margin-top: 5%;
  }
}
