.timer-container {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;

  .program-title {
    font-weight: 700;
    color: #56576f;
    margin: 0;
  }

  .time-select {
    flex-direction: row;
    display: flex;

    .day {
      margin: auto;
      height: 30px;
      background-color: #e4dff2;
      text-align: center;
      padding-left: 3px;
      border-radius: 20px;
      font-weight: 500;
      cursor: pointer;
      align-items: center;
      display: flex;
      margin-right: 10px;
      color: #56576f;
      font-weight: 700;

      > p {
        padding-right: 10px;
        margin: 0;
        margin-left: 5px;
      }

      > svg {
        width: 25px;
      }
    }

    .day:hover {
      background-color: #ccc5e0;
    }

    .day-active {
      margin: auto;
      margin-right: 10px;
      height: 30px;
      background-color: #e4dff2;
      text-align: center;
      padding-left: 3px;
      border-radius: 20px;
      font-weight: 700;
      cursor: pointer;
      color: white;
      background-color: #56576f;
      align-items: center;
      display: flex;

      > p {
        padding-right: 10px;
        margin: 0;
        margin-left: 5px;
      }

      > svg {
        width: 25px;
      }
    }
  }
}

@media screen and (max-width: 577px) {
  .timer-container {
    align-items: center;
    margin-bottom: 20px;

    .animator-count {
      margin-right: -10px;
    }
  }
}
