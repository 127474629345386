.dashboard-top-menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & svg {
    cursor: pointer;
  }

  .signed-user {
    padding: 10px;
    display: flex !important;
    flex-direction: row;

    > img {
      border-radius: 50%;
      width: 35px;
      margin-right: 5px;
    }
    .name {
      color: black;
      margin: 0;
    }
  }
}

.dashboard-navigation {
  display: flex;
  flex-direction: column;

  .dashboard-navigation-link {
    padding: 10px;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .dashboard-navigation-link:hover {
    background-color: #242a39;
    border-radius: 5px;
  }

  .active-link {
    color: red;
    background-color: #242a39;
    border-radius: 5px;
  }
}

.modal-backdrop {
  z-index: 10000 !important;
}
.modal {
  z-index: 10001 !important;
}
