.timeline-container-evening-sum,
.timeline-container-sum {
  width: 100%;
  max-width: 1050px;
  display: block;
  height: 100%;
  background-color: #f8f5ff;
  border-radius: 15px 15px 0px 0px;
  padding-bottom: 40px;
  position: relative;

  .margin {
    margin-bottom: 20px;
  }

  .timeline-navbar {
    height: 60px;
    background-color: #e4dff2;
    max-width: 1050px;
    border-radius: 15px 15px 0px 0px;
    .navbar-title {
      text-align: center;
      color: #56576f;
      font-size: 26px;
      font-weight: 600;
      padding-top: 10px;
    }
  }
  .timeline-title {
    padding: 20px 20px 10px 20px;
    color: #56576f;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }
  .timeline-title-evening {
    padding: 20px 20px 10px 20px;
    margin: 0;
    color: white;
    font-weight: 700;
    font-size: 20px;
  }

  .category-title {
    color: white;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      opacity: 0.5;
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      width: 90px;
    }

    div {
      margin: 0;
      margin-right: 10px;
      height: 2px;
      min-width: 40%;
      background-color: #ffffff;
      opacity: 0.3;
    }
  }

  .show-time-row {
    height: 25px;
    margin: 5px 20px;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      color: white;
      font-size: 12px;
    }

    .show-time-row-block {
      display: flex;
      color: white;
      width: fit-content;
      font-size: 12px;
      font-weight: 700;
      margin: 5px 0 5px 0;
      height: 25px;
      border-radius: 17px;
      padding: 0 7px;
      align-items: center;
      margin-left: 10px;

      .delete-activity {
        margin: 0;
        margin-left: 5px;
        font-size: 18px;
        font-weight: 600;

        z-index: 10;
        color: #ffffff;
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  .columns-timeline-evening,
  .columns-timeline-sum {
    display: flex;
    flex-direction: row;

    .time-line {
      font-size: 12px;
      margin: 5px 0 5px 20px;
      height: 25px;
      display: flex;
      align-items: center;
    }
    .time-line-evening {
      font-size: 12px;
      margin: 5px 0 5px 20px;
      height: 25px;
      display: flex;
      align-items: center;
      color: rgb(244, 244, 244);
    }
  }
  .columns-timeline-evening {
    min-height: 250px;
  }
  .arrow-down {
    position: absolute;
    bottom: -16px;

    z-index: 6;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .unlock-button {
    width: 150px;
    background-color: #3cd19e;
    border-color: #3cd19e;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 125px;
  }

  .unlock-text {
    margin: auto;
    font-weight: 700;
    font-size: 15px;
  }

  .activities-counter-container {
    bottom: 10px !important;
  }
}

.key-value {
  display: flex;

  .activities-map-container {
    margin-left: 10px;
    .activities-day {
      display: flex;
      color: white;
      width: fit-content;
      font-size: 12px;
      font-weight: 700;
      margin: 5px 0 5px 0;
      height: 25px;
      border-radius: 17px;
      padding: 0 7px;
      align-items: center;
      .delete-activity {
        margin: 0;
        margin-left: 5px;
        font-size: 18px;
        font-weight: 600;

        z-index: 10;
        color: #ffffff;
        opacity: 0.6;
        cursor: pointer;

        > svg {
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }
  }
}

.timeline-container-evening-sum {
  background-color: #271854;
  border-radius: 0px 0px 15px 15px;
  position: relative;
}

@media screen and (max-width: 450px) {
  .category-title {
    div {
      width: 70%;
    }
  }
}
