.container-screen-three {
  display: flex;
  flex-wrap: wrap;

  .section-summary-day-evening {
    flex: 1;
    max-width: 1050px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1426px) {
  .section-summary-day-evening {
    min-height: auto !important;
  }
}

@media screen and (max-width: 580px) {
  .section-summary-day-evening {
    min-width: 300px !important;
  }
}
