.first-screen-layout {
  width: 100vw;
  height: 100vh;

  .main-title {
    text-align: center;
    color: white;
    padding-top: 7%;
    font-size: 30px;
    font-weight: 700;
  }

  .subtitle {
    text-align: center;
    color: white;
    margin-top: 1%;
    margin-bottom: 5%;
    font-size: 20px;
    font-weight: 300;
    padding: 0 10px;
  }
}
