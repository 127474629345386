.modal-container-program {
  height: 100%;
  border-radius: 20px;
  padding: 20px;

  .container-modal-program {
    background-color: #f8f5ff;
    border-radius: 20px;

    .title-modal-program {
      width: 350px;
      text-align: center;
      font-size: 22px;
      color: #56576f;
      font-weight: bold;
      margin: auto;
      margin-top: 20px;
    }
    .modal-program-info {
      width: 350px;
      text-align: center;
      margin: auto;
      color: #56576f;
      font-weight: 400;
      padding: 5px;
      p {
        margin: 0;
      }
    }
    .modal-program-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
      margin: auto;
      justify-content: center;
      .button-cancel-select {
        padding: 10px;
        width: 200px;
        height: 37px;
        text-align: center;
        justify-content: center;
        border-radius: 20px;
        font-weight: 700;
        cursor: pointer;
        color: white;
        background-color: #56576f;
        align-items: center;
        display: flex;
        margin-right: 10px;
      }
      .button-deactivate {
        padding: 10px;
        width: 200px;
        height: 37px;
        text-align: center;
        justify-content: center;
        border-radius: 20px;
        font-weight: 700;
        cursor: pointer;
        color: white;
        background-color: #3cd19e;
        align-items: center;
        display: flex;
        margin-right: 10px;
      }
      .button-deactivate:hover {
        background-color: #4ae2ae;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .modal-content {
    width: 350px;
    margin: auto;
  }
}
