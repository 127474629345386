.count-day-container {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .count-title {
    text-align: center;
    font-weight: 700;
    color: #56576f;
    margin: 0;
  }

  .day-count {
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    .number-day-active {
      margin: auto;
      width: 70px;
      height: 30px;
      text-align: center;
      justify-content: center;
      border-radius: 20px;
      font-weight: 700;
      cursor: pointer;
      color: white;
      background-color: #56576f;
      align-items: center;
      display: flex;
      justify-content: space-around;

      > svg {
        margin-bottom: 5px;
      }
    }
  }
  .column-days {
    z-index: 5;
    margin: auto;
    margin-top: 5px;
    width: 70px;
    justify-content: center;
    border-radius: 20px;
    font-weight: 700;
    cursor: pointer;
    color: white;
    background-color: #56576f;
    .top-active-number {
      justify-content: space-around;
      align-items: center;
      display: flex;
      margin-top: 5px;

      > svg {
        margin-top: 5px;
      }
    }
    .divider {
      text-align: center;
      height: 1px;
      background-color: #ccc5e0;
      width: 90%;
      margin: auto;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .row-number {
      width: 64px;
      margin: auto;
      .number-dropdown {
        margin: 0;
        margin-bottom: 10px;
        color: white;
        margin-left: 10px;
        font-size: 16px;
      }
      .number-dropdown-active {
        margin: 0;

        margin-bottom: 10px;
        color: #ccc5e0;
        margin-left: 10px;
        font-size: 16px;
      }
    }
    .row-number:hover {
      p {
        color: #fff;
      }
      border-radius: 20px;
      // margin: 0;
      background-color: #ccc5e0;
    }
  }
}
