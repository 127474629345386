.modal-content {
  border: none !important;
  border-radius: 20px !important;
  background-color: transparent !important;
}
.modal-container {
  max-width: 600px !important;

  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  -webkit-border: 0px !important;
  -moz-border: 0px !important;
  border: 0px !important;
  border-width: 0;
  .image-container {
    height: 180px;
    width: 100%;
    max-width: 600px;

    bottom: 165px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
  }

  .bottom-detail-container {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    border-radius: 0px 0px 30px 20px;

    .bottom-left-container {
      bottom: 0px;
      margin-bottom: 20px;

      .detail-title {
        font-size: 20px;
        margin: 0;
        margin-left: 20px;
        font-weight: 700;
        color: #56576f;
      }

      .detail-describtion {
        font-size: 14px;
        margin: 0;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 340px;
        min-height: 120px;
      }
    }
    .info-tab-detail {
      width: 200px;
      height: 220px;
      border-radius: 20px 0px 20px 0px;
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 0;

      .info-sub-container {
        margin: auto;
        width: 150px;
        margin-top: 50px;
        text-align: center;

        svg {
          fill: white;
        }

        .image-icon-detail {
          filter: invert(100%) sepia(89%) saturate(0%) hue-rotate(163deg)
            brightness(109%) contrast(100%);
        }

        .activity-kind {
          font-weight: 700;
          font-size: 16px;
          color: white;
          margin: 0;
          text-align: center;
        }
      }

      .close-container {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;

        .close-info-detail {
          width: 120px;
          height: 45px;
          background-color: black;
          color: white;
          opacity: 0.2;
          margin-left: auto;
          border-radius: 10px 0px 20px 0px;
        }
        .close-info-text {
          font-size: 16px;
          left: 0;
          right: 0;
          text-align: center;
          margin-top: 10px;
          position: absolute;
          top: 0;
          color: white;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    margin: auto;
    .bottom-detail-container {
      flex-wrap: wrap;
      .info-tab-detail {
        position: relative;
        height: 70px;
        width: 100%;
        border-radius: 0px 0px 20px 20px;
        .info-sub-container {
          display: flex;
          align-items: center;
          width: fit-content;
          margin-top: 5px;
          .icon-tag-detail {
            margin-right: 10px;
          }
          img {
            margin-right: 10px;
          }
        }
        .close-info-detail {
          height: 35px;
        }
        .close-info-text {
          text-align: center;
          margin: 0;
          padding: 5px;
        }
      }
    }
  }
  .detail-describtion {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 420px) {
  .modal-content {
    margin: auto;
  }
}
