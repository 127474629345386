.schedule-day {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 125px;

  p {
    margin: 0;
  }

  .schedule-activities {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .schedule-activity {
      background-color: silver;
      height: 68px;
      padding-top: 10px;
      text-align: center;
      line-height: 1;
    }

    .schedule-activity-evening {
      background-color: blue;
      color: white;
      height: 48px;
      padding-top: 10px;
      text-align: center;
      line-height: 1;
    }

    .schedule-activity-empty {
      background-color: white;
      color: white;
      height: 68px;
      line-height: 1;
    }

    .schedule-activity-evening-empty {
      background-color: white;
      color: white;
      height: 48px;
      line-height: 1;
    }
  }
}
