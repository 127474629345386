.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .MuiCircularProgress-root {
    color: red;
  }
}
