.count-animator-container {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .count-title {
    font-weight: 700;
    color: #56576f;
    margin: 0;
  }

  .animator-count {
    flex-direction: row;
    display: flex;

    .number-animators {
      margin: 0;
      width: 45px;
      height: 30px;
      background-color: #e4dff2;
      color: #56576f;
      text-align: center;
      justify-content: center;
      border-radius: 20px;
      font-weight: 700;
      cursor: pointer;
      align-items: center;
      display: flex;
      margin-right: 10px;
    }

    .number-animators:hover {
      background-color: #ccc5e0;
    }

    .number-animators-active {
      margin: auto;
      width: 45px;
      height: 30px;
      text-align: center;
      justify-content: center;
      border-radius: 20px;
      font-weight: 700;
      cursor: pointer;
      color: white;
      background-color: #56576f;
      align-items: center;
      display: flex;
      margin-right: 10px;
      justify-content: space-around;
    }
  }
  .column-days {
    margin-top: 30px;
    margin-left: 20px;
    position: absolute;
    width: 45px;
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    font-weight: 700;
    cursor: pointer;
    color: white;
    background-color: #ccc5e0;
    .number-dropdown {
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #56576f;
    }
    .number-dropdown:hover {
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      color: hotpink;
    }
  }
}

@media screen and (max-width: 577px) {
  .count-animator-container {
    height: 60px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .animator-count {
      margin-right: -10px;
    }
  }
}
