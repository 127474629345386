.order-detail-container {
  margin: 10px;

  .oder-detail-header {
    border-radius: 10px;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  }

  .order-detail-config {
    border-radius: 10px;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);

    p {
      margin: 0;
    }
  }
}
