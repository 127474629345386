.form-container {
  background-color: #f8f5ff;
  border-radius: 15px;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;

  .form-title {
    color: #56576f;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
  .form-name {
    width: 300px;
    margin: auto;
    margin-bottom: 10px;

    .form-label {
      font-size: 14px;
      font-weight: 300;
      margin: 0;
      margin-bottom: 3;
    }

    .form-control {
      border: none;
      background-color: #e4dff2;
      border-radius: 8;
    }

    p {
      font-weight: bold;
      color: #56576f;
      margin: 0;
      margin-top: -5px;
    }
  }
  .forms {
    margin: auto;
  }
  .button-send {
    height: 54px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #46d19e;
    border-color: #46d19e;
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    border: none;
    color: white;
  }

  .button-send:hover {
    background-color: #4ae2ae;
    color: white;
  }
}

@media screen and (max-width: 450px) {
  .form-name {
    width: 100% !important;
  }
}
