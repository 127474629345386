.dashboard-activities-container {
  padding: 20px;
  .activities-form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .buttoms-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      height: 50px;
    }
  }
  textarea {
    margin-top: 20px;
  }
}
.activities-edit-delete {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin: 10px;

  > div {
    min-height: 100px;
    border-radius: 10px;
    color: #fff;
  }
}

.dashboard-activity-card {
  padding: 10px;

  svg {
    margin: 10px;
    cursor: pointer;
  }
}

.MuiModal-root {
  z-index: 20000 !important;
}

.dashboard-modal-edit-activity {
  .MuiFormControl-root {
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 1270px) {
  .activities-edit-delete {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1020px) {
  .activities-edit-delete {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 720px) {
  .activities-edit-delete {
    grid-template-columns: repeat(1, 1fr);
  }

  .activities-form-row {
    flex-direction: column !important;
  }
}
