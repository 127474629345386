.date-start-block {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;

  input {
    border-width: 0px;
    background-color: transparent;
    font-weight: 700;
    color: #56576f;
    font-size: 15px;
    width: 85px;
    cursor: pointer;
    margin-left: 10px;
    outline: none;
  }

  img {
    margin-left: 10px;
  }

  .MuiInputBase-root::before {
    border-bottom: none !important;
  }

  .MuiInputBase-input {
    padding: 0 !important;
  }

  .date-container {
    margin: 0;
    height: 30px;
    background-color: #e4dff2;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    border-radius: 20px;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin-right: 10px;

    svg {
      margin-right: 10px;
    }
  }

  .date-container:hover {
    background-color: #ccc5e0;
  }

  .count-title {
    font-weight: 700;
    color: #56576f;
    margin: 0;
  }
}

.MuiPopover-paper {
  border-radius: 20px !important;
  margin-top: 8px !important;
}

.MuiPickersDay-current {
  color: #502582 !important;
}

.MuiPickersDay-daySelected {
  background-color: #502582 !important;
  color: #fff !important;
}

.MuiTypography-alignCenter {
  font-weight: bold !important;
  color: #56576f;
}

@media screen and (max-width: 577px) {
  .date-start-block {
    align-items: center;
    margin-bottom: 20px;

    .date-container {
      margin-right: -10px;
    }
  }
}
