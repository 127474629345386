.select-activities-container-evening,
.select-activities-container {
  max-width: 1190px;
  width: 100%;
  height: auto;
  background-color: #f8f5ff;
  border-radius: 15px 15px 15px 2px;
  margin: 5px;

  .select-activities-subcontainer {
    position: relative;
    width: 100%;
    height: 100%;

    .lock-container-evening,
    .lock-container {
      max-width: 1190px;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 20px;
      opacity: 0.8;
      position: absolute;
      z-index: 4;
    }
    .lock-container-evening {
      border-radius: 2px 15px 15px 15px;
      background-color: #271854;
      opacity: 0.8;
    }

    .lock-center {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 5;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .lock {
        width: calc(100% - 20px);
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .lock-notice {
          font-size: 20px;
          font-weight: 700;
          margin: 0;
          margin-left: 20px;
          color: #56576f;
        }
        .lock-notice-evening {
          font-size: 20px;
          font-weight: 700;
          margin: 0;
          margin-left: 20px;
          color: white;
        }
      }
    }

    .tags-bar {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      padding-top: 15px;
      padding-bottom: 10px;

      .activities-kind {
        padding: 10px;
        color: #56576f;
        font-weight: 700;
        font-size: 15px;
        margin: 0;
      }

      .activities-kind-evening {
        padding: 10px;
        color: white;
        font-weight: 700;
        font-size: 15px;
        margin: 0;
      }
    }

    .activity-cards {
      margin: 8px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .activity-card-container {
        padding-right: 30px;
      }

      .activity-card-box {
        // width: 275px;
        justify-self: stretch;
      }
    }
  }
}

.select-activities-container-evening {
  background-color: #271854;
  border-radius: 2px 15px 15px 15px;
}

@media only screen and (max-width: 1510px) {
  .activity-cards {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media only screen and (max-width: 1220px) {
  .activity-cards {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 630px) {
  .activity-cards {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media screen and (max-width: 900px) {
  .select-activities-container {
    border-radius: 15px;
  }
  .select-activities-container-evening {
    border-radius: 15px;
  }
}
