.pdf-container {
  background-color: #f8f5ff;
  border-radius: 15px;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;

  .pdf-title-row {
    display: flex;
    align-items: center;
    width: 200px;
    margin: 20px auto;

    p {
      margin: 0;
      margin-left: 10px;
      color: #56576f;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .button-pdf {
    padding: 8px 25px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #e4dff2;
    color: #56576f;
    border: none;

    .button-font {
      margin: 0px;
      margin-left: 10px;
      font-weight: 600;
      font-size: 15px;
    }
  }

  .button-pdf:hover {
    background-color: #ccc5e0;
  }

  .button-pdf-disabled:hover {
    cursor: default !important;
    background-color: #e4dff2;
    color: #56576f;
  }
}

@media screen and (max-width: 450px) {
  .pdf-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
