.third-creen-container {
  padding-top: 5%;
  padding-bottom: 10px;
}
.send-order-container {
  margin-bottom: 50px;
  .program-done {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    margin: auto;
    justify-content: center;
    margin-top: 20px;

    .congratulation-icon-container {
      margin-right: 15px;
    }

    .congratulation {
      font-size: 40px;
      color: white;
      font-weight: 600;
      margin: 0;
      margin-right: 10px;
    }

    .your-program {
      font-size: 40px;
      font-weight: 100;
      margin: 0;
      color: white;
    }
  }

  .info-program {
    margin: auto;
    margin-top: 15px;
    padding: 0 15px;
    text-align: center;
    color: white;
    max-width: 660px;
    font-weight: 300;
  }
  .summary-and-form {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 1410px;
    justify-content: space-around;
    margin-top: 20px;

    .activities-sum-component {
      max-width: 1050px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 450px) {
  .activities-sum-form-pdf {
    max-width: 430px;
    width: 100%;
  }
}
