.tags-container {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  .tags-shimmer {
    width: 150px;
    height: 35px;
    margin-right: 10px;
    border-radius: 10px;
  }
}
.single-tag-evening,
.single-tag {
  height: 35px;
  margin: 5px;
  background-color: #e4dff2;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #56576f;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  .tag-title {
    margin-left: 5px;
    user-select: none;
  }

  .single-tag-shimmer {
    margin: 0;
    background-color: transparent;
  }
}
.single-tag:hover {
  background-color: #ccc5e0;
}

.single-tag-evening {
  background-color: #473a6e;
  color: #a39db7;
}
.single-tag-evening:hover {
  background-color: #6e6585;
  color: #b6b2c2;
}
.single-tag-active {
  height: 35px;
  margin: 5px;
  background-color: #ccc5e0;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #56576f;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  .tag-title {
    margin-left: 5px;
  }
}
