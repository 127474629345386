.basic-selection {
  margin: 10px;

  .basic-selection-container {
    max-width: 1592px;
    background-color: #f8f5ff;
    margin: auto;
    border-radius: 20px;
    padding: 20px;

    .inside-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .forms-row {
        justify-content: space-between;

        .activities-link {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;

          .button-s1-confirm {
            width: 150px;
            background-color: #46d19e;
            border-color: #46d19e;
            border-radius: 20px;

            .button-text {
              margin: auto;
              font-weight: 700;
              font-size: 15px;
            }
          }
          .button-s1-confirm:hover {
            background-color: #4ae2ae;
            border-color: #4ae2ae;
          }
        }
      }

      .forms-row > div {
        margin: 0 10px 0 10px;
      }
    }
  }
}

@media screen and (min-width: 577px) {
  .forms-row {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
}
