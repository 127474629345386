.second-screen-container {
  padding-top: 5%;
  padding-bottom: 10px;

  .day-activity {
    margin: auto;
    max-width: 1592px;
    margin-bottom: 100px;
    .confirm-choice-container {
      text-align: center;
      .button-accept {
        width: 150px;
        margin-top: 50px;
        background-color: #3cd19e;
        border-color: #3cd19e;
        border-radius: 20px;
        color: white;
        border: none;
        outline: none;

        .button-accept-text {
          margin: auto;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .button-accept:hover {
        background-color: #4ae2ae;
        border-color: #4ae2ae;
      }
    }
  }
}
